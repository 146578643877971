import React from 'react';
import Button from './Button';
import {Link} from  'react-router-dom';
import IconBox from './IconBox';

const HomePage = () => {


    return (
        <div className="homepage">
            
            <h1 className="text title">ALEJANDRO SANCHEZ</h1>
            <h2 className="text">Desarrollador Web</h2>
            <div className="button__container">
                <Link to="/Projects">
                <Button content="Projects"/>
                </Link>
                <Link to="/About">
                <Button content="About me"/>
                </Link>
                
                {/* <Button content="Contactame"/> */}

            </div>
            <IconBox/>
        </div>
    )
}

export default HomePage;