import React from 'react';

const Button = (props) =>{

    return (
        <div className= "button">
            <p className="text">{props.content}</p>
        </div>
    )
}

export default Button;