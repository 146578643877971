import React , {useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import beelBuddy from './images/beelBuddy.png';
import Modal from './Modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GitHubIcon from '@material-ui/icons/GitHub';
const Projects = () => {
    const [show, setShow] = useState(false);


    console.log(show);
    return (
        <div className="projects" >
                <Header title ="Projects"/>


                <div className="projects__container"  >

                    <div className="card__container" onClick={()=> setShow(true)}>

                        <img src={beelBuddy} alt=""/>
                        <h3>Beelbuddy</h3>
                        <button>See more</button>
                    </div>

                    
                    
                </div>
                <Modal show={show} handleClose={()=>setShow(false)}>
                    <div className="modal__headerContainer">
                        <h3>Beelbuddy</h3>
                        <HighlightOffIcon onClick={()=>setShow(false)} />
                    </div>

                    <div className="modal__imgContainer">
                    </div>

                    <article>
                        <p>
                        This was the final project that I made with my Migracode team. We needed to create a platform that made the connection between social work students (buddies) and clients that needed the service (patients), through an administrator who is responsible for matching buddies and patients.
                    </p>
                    <br/>
                        <p>
                        In this project, I created the header component, as well as the connection between the front-end and the back-end server, in order to send and receive the values of the form, set up the authentication for the admin. I also deployed the app to Heroku.</p>
                    </article>
                    <article>
                    <hr/>
                        <h4>Technologies Used:</h4>
                        <p>React, Node, Sass, PostgreSQL</p>
                    </article>
                    <div className="modal__btnContainer">

                        <a href="http://beeldbuddy.nl"><button>Go to the website</button></a>
                        <a href="https://github.com/apix23/beel-buddy"><button>GitHub Repo {<GitHubIcon fontSize="small"/>}</button></a>
                    </div>
                </Modal>
                <Footer></Footer>
        </div>
    )
}
export default Projects;