import React from 'react';
import HomePage from './HomePage';
import './styles/directoring.scss';
import About from './About'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Projects from './Projects';
import Contact from './Contact'

function App() {
  return (
    <Router>

    <div className="App">
      <Route exact path="/">
      <HomePage/>
      </Route>
      <Route path="/Projects" component={Projects}/>
      <Route path="/About" component={About}/>
      <Route path="/contact" component={Contact}/>
    </div>
    </Router>
  );
}

export default App;
