import React from 'react';

const Modal = ({handleClose, show, children}) => {
    const showHideClassName = show ? "modal display__block" : "modal display__none";
    return (
        <div className={showHideClassName}>
            <section className="modal__main">
            {children}
            </section>

        </div>
    )
}

export default Modal;
