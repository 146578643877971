import React from 'react';
import Button from './Button';
import {Link} from  'react-router-dom';
import personalImage from './images/personal_photo.jpg'

const About = ()=> {
    return (
        <div className="aboutBg">
            <div className="circular__imageContainer">
                    {/* <img src={personalImage} className="imageResponsive" alt=""/> */}
                </div>
            <div className="container">
                
                
                <h1>About Me</h1>
                
                <article>
                <p>Hi, thank you for being here!</p>
                <p>I was born in Venezuela, but I am currently located in Barcelona, Spain.</p>
                <p>When I arrived here I had the opportunity to take a Bootcamp with <a href="https://migracode.openculturalcenter.org/">Migracode Barcelona</a>, which helped me recover the confidence in myself and in my skills.
                In this Bootcamp, we worked with Git, React.js, Node.js, PostgreSQL, scrum methodologies, and VScode as an IDE.
                </p>
                <p>
                I consider myself proactive and committed to each one of the activities I do. I would say one of my weaknesses is the design, I prefer to do the layout and develop
                functionalities. I’m very patient and as a team player, is easy for me to give my opinion about a project. On the other hand, I like others to give suggestions about
                my work or any kind of advice. I know I don’t know everything and that you can always learn from others.
                </p>
                <p>
                My last job was a substitution by paternity leave at <a href="https://avanttic.com/">avanttic</a>, I worked as a system administrator. I started this position at the beginning of the pandemic, so I’m getting used to
                working remotely. There, I worked fixing some laptop problems, working with the G-suite platform, inventory management, and company registrations and delistings.
                </p>
                <p>
                By today I have already finished the Bootcamp and I’m actively looking for job opportunities. I’m looking for a friendly place where I can grow personally and professionally.
                </p>
                </article>
                
                <div className="button__container">
                    <Link to="/Projects"><Button content="Projects"/></Link>
                    {/* <Link to="/Contact"><Button content="Contact"/></Link> */}
                </div>
            </div>
        </div>

    )
}

export default About
