import React from 'react';
import {NavLink} from 'react-router-dom';

function Header(props) {
    return (
        <header>
            <h1 className="header__title" >{props.title}</h1>
            <nav>   
            <NavLink to="./Projects" ><p>Projects</p></NavLink>
            <NavLink to="./About" ><p>About me</p></NavLink>
            </nav>
            
        </header>
    )
}

export default Header
