import  React  from "react";
import Header  from "./Header";
import Footer from './Footer'
const Contact = () => {
    return (
        <div className="contact">
            <Header title ="Projects"/>
                <div className="projects__container">
                    
                </div>
                <Footer></Footer>
        </div>
    )
}

export default Contact;