import React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {Link} from 'react-router-dom'

const IconBox = () => {

    return(
        <div className="icon__box">
                
                <a href="https://github.com/apix23">
                <GitHubIcon  className="icon footer__gitHubIcon"></GitHubIcon>
                </a>
                
                <a href="https://www.linkedin.com/in/aljosago/">
                <LinkedInIcon className="icon footer_linkedInIcon"></LinkedInIcon>
                </a>
        </div>
    )
}
export default IconBox;