import React from 'react';
import IconBox from './IconBox'

const Footer = () => {

    return (
        <div className="footer" >
            <IconBox/>
            <p className="footer__text">Developed by Alejandro Sanchez</p>
        </div>
    )

}

export default Footer;